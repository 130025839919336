<template>
    <div class="card rounded-xl shadow overflow-hidden">
        <div class="position-relative cover bg-dark border-bottom">
            <img class="w-100 h-100"
                 v-if="cover"
                 v-bind:alt="name"
                 v-bind:src="cover">
            <div class="position-absolute avatar rounded-circle overflow-hidden bg-white shadow-sm p-1">
                <img class="w-100 h-100 rounded-circle"
                     v-if="avatar"
                     v-bind:alt="name"
                     v-bind:src="avatar">
                <img class="w-100 h-100 rounded-circle"
                     src="../../../assets/avatar.png"
                     v-else
                     v-bind:alt="name">
            </div>
        </div>
        <div class="card-body bg-light pt-5">
            <h1 class="h3 text-center font-weight-bolder">
                <strong>{{ name }}</strong>
            </h1>
            <h5 class="text-center font-weight-normal mb-3">
                +{{ countryCode }} {{ phone }}
            </h5>
            <p class="intro mb-0">
                {{ intro }}
            </p>
        </div>
    </div>
</template>

<script>
import { apiUrl, apiVersion2, } from "@/config";
export default {
    name: "OrganizerCard",
    props: {
        organizer: {
            type: Object,
        },
    },
    components: {

    },
    data () {
        return {

        };
    },
    computed: {
        id () {
            return this.organizer?._id ?? "";
        },
        name () {
            return this.organizer?.profile?.nickname || this.organizer?.name || "";
        },
        countryCode () {
            return this.organizer?.countryCode ?? "";
        },
        phone () {
            return this.organizer?.phone ?? "";
        },
        intro () {
            return this.organizer?.profile?.organizerIntroduction ?? "";
        },
        avatar () {
            return this.organizer?.avatar ?
                `${ apiUrl }/${ apiVersion2 }/user/${ this.id }/avatar` :
                "";
        },
        cover () {
            return this.organizer?.cover ?
                `${ apiUrl }/${ apiVersion2 }/user/${ this.id }/cover` :
                "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.cover {
    height: 200px;

    img {
        object-fit: cover;
    }
}

.avatar {
    --width: 125px;
    --height: 125px;

    left: calc(50% - var(--width) / 2);
    bottom: -15%;

    width: var(--width);
    height: var(--height);

    img {
        object-fit: cover;
    }
}

.intro {
    white-space: pre-line;
    word-break: break-word;
}
</style>
